import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { graphql } from 'gatsby'
import useDateFns from '@hooks/useDateFns'
import { get } from 'lodash'
import Img from 'gatsby-image'
import clsx from 'clsx'

import TracklineBackground from '@objects/tracklineBackground'
import useStaticModule from '@hooks/useStaticModule'
import DoubleBlogCard from '@components/doubleBlogCard'
import Stage from '@components/stage'
import Container from '@objects/container'
import Copy from '@objects/copy'
import Headline from '@objects/headline'
import PageDownload from '@components/pageDownload'

function Fakten({ data, pageContext }) {
  const intl = useIntl()
  const { differenceInDays } = useDateFns()
  const {
    getStaticFieldMedia,
    getStaticFieldValue,
    getAllStaticFacts,
  } = useStaticModule(pageContext.modules)

  const stage = {
    image: getStaticFieldMedia('fact.stage', 'fact.stage.image'),
    article: {
      headline: getStaticFieldValue('fact.stage', 'fact.stage.headline'),
      tag: {
        name: getStaticFieldValue('fact.stage', 'fact.stage.tag'),
      },
    },
  }
  const facts = getAllStaticFacts('fact.facts')

  const blogCards = {
    headline: intl.formatMessage({ id: 'section.blogdouble.headline' }),
    button: {
      label: intl.formatMessage({ id: 'button.blog' }),
      to: '/blog/',
    },
    cards: (() => {
      // get first 2 articles in DESC order
      return get(data, 'blogArticles')
        ?.articles.sort((a, b) => differenceInDays(b.createdAt, a.createdAt))
        .slice(0, 2)
        .map((card) => ({
          image: card.image,
          headline: card.title,
          copy: card.teaserCopy?.childMarkdownRemark?.html,
          tag: card.category?.title,
          date: card.createdAt,
          link: card.slug,
        }))
    })(),
  }

  function renderFacts() {
    return facts?.map((fact, i) => {
      const margins = i === facts.length - 1 ? '-mb-8' : 'mb-4 md:mb-9'
      return (
        <div
          key={i}
          className={clsx('flex flex-col md:block md:clearfix', margins)}
        >
          <Img
            className={clsx('order-3 md:w-5/12 mb-8', {
              'md:float-right md:ml-7': !(i % 2),
              'md:float-left md:mr-7': i % 2,
            })}
            fluid={{ ...fact.image.fluid, media: `(min-width: 0px)` }}
            alt={fact.image.description}
          />
          <Headline className="mb-6 md:mb-8" ariaLabel={fact.headline}>
            {fact.headline}
          </Headline>
          <Copy parseGlossaryCopy className="mb-8" html={fact.copy} div />
        </div>
      )
    })
  }

  const downloads = []
  const pdf = getStaticFieldMedia('fact.pdf', 'fact.pdf.file')
  pdf && downloads.push(pdf)

  return (
    <>
      <Stage type="headlineimage" {...stage} />
      <Container role="region" aria-label="Fakten">
        <TracklineBackground track={5} top={'10%'} />
        <TracklineBackground track={3} bottom={'30%'} />
        <TracklineBackground track={2} right mirror top={'20%'} />
        <TracklineBackground track={1} right bottom={'15%'} />

        {renderFacts()}
        {(!!downloads.length && <PageDownload files={downloads} marginTop />) ||
          null}
      </Container>
      <DoubleBlogCard
        className="mb-11"
        headline={blogCards.headline}
        button={blogCards.button}
        cards={blogCards.cards}
      />
    </>
  )
}

Fakten.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Fakten

export const pageQuery = graphql`
  query FaktenPageQuery($locale: String!) {
    blogArticles: contentfulArticleList(
      node_locale: { eq: $locale }
      identifier: { eq: "blog" }
    ) {
      articles {
        slug
        title
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        createdAt
        image {
          fluid(quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        category {
          title
        }
      }
    }
  }
`
